import facebookConversionsAPI from '../tools/facebookConversionsApi'; //轉換API
import axios from 'axios';

$(function(){
    new products();
})

class products {
    
    // 建構值
    constructor() {
        this.fb = new facebookConversionsAPI();
        this.metaIdAndTokenExist = this.fb.checkIsMetaIdAndToken();
        this.productCurrency = 'TWD';
        this.init();
    }

    // 初始化
    init() {
        this.bindMetaApiEvents()
    }

    // 監聽事件: meta Pixel & 轉換 API 事件
    bindMetaApiEvents() {

        // 如果有 fb pixel id & 權杖 才發送資料
        if ( this.metaIdAndTokenExist == true ) {

            //加入購物車時，將該商品與用戶資料一起傳送 
            $('body').on('click', '.addToCartButton', (e) => {
                // 獲取當前按鈕並傳送
                const button = $(e.currentTarget);
                this.getUserData()
                    .then(userData => {
                        this.getAddToCartData(button, userData); // 传递按钮元素
                    });              
            });
        
        } else {
            console.log('no meta pixel ID & Access Token')
        }
    }

    //加入購物車時，將該商品與用戶資料一起傳送 ================================
    getAddToCartData(button, userData) {
        const productBox = button.closest('.productBox');
        const productIdText = productBox.find('input[name="productId"]').val().trim();

        // 優先獲取優惠價格
        const productPriceText = productBox.find('.js-sale-price').length > 0 ?
            productBox.find('.js-sale-price').text().trim() :
            productBox.find('.js-price').text().trim();

        const productId = parseInt(productIdText.replace(/[^\w\d]/g, '')); 
        const productPrice = parseInt(productPriceText.replace(/[^\w\d]/g, '')); 
        const addToCartProduct = [];

        addToCartProduct.push({
            id: productId,
            item_price: productPrice,
        });

        const addToCartData = {
            "contents": addToCartProduct,
            "currency": this.productCurrency,
        }

        console.log(userData, addToCartData);
        // // meta pixel
        // fbq('track', 'AddToCart', 
        //     { contents: addToCartProduct, currency: this.productCurrency }, 
        //     { eventID: 'event.id.AddToCart' });
        
        // // mete conversions api
        // this.fb.sendAddToCart(userData, addToCartData);
    };

    // 獲取當前使用者會員資料
    getUserData() {
        return new Promise((resolve, reject) => {
    
            // 如果用戶已經登錄，則發送 Axios 請求獲取用戶數據
            axios.get('/facebook-conversions-api/me')
            .then(response => {
            resolve(response.data);
            })
            .catch(error => {
            reject(error);
            });
        
        });
    };
}